import { useQuery } from "@vue/apollo-composable";
import { PropertyRentRegulationPrinciplesDocument } from "~/graphql/generated/graphql";
export var usePropertyRentRegulationPrinciplesQuery = function usePropertyRentRegulationPrinciplesQuery(propertyId) {
  var query = useQuery(PropertyRentRegulationPrinciplesDocument, {
    id: propertyId
  });
  var result = computed(function () {
    var _query$result$value, _query$result$value$a;
    return (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : (_query$result$value$a = _query$result$value.assetManagementProperty) === null || _query$result$value$a === void 0 ? void 0 : _query$result$value$a.tenancyRentRegulationPrinciples;
  });
  return {
    loading: query.loading,
    result: result
  };
};